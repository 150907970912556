/*
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2022-09-28 15:22:34
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './util/rem'
import './permission' // permission control
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import '../theme/index.css'
import * as echarts from 'echarts';


import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import Vue2OrgTree from 'vue-tree-color'
Vue.use(Vue2OrgTree)

import { download } from '@/util/request';
Vue.prototype.download = download

import Print from 'vue-print-nb'
Vue.use(Print)
// 全局输入框去空格
import "@/util/el-components.js"

import { numberToCurrencyNo, isDollar, isLocal, stateFormat, toThousandthAndKeepDecimal } from '@/util/numberToCurrency'
import Pagination from "@/components/pagination";
Vue.component('Pagination', Pagination)
// 配置全局过滤器，实现数字千分位格式
Vue.filter('numberToCurrency', numberToCurrencyNo)
//海外pv添加美元
Vue.filter('isDollar', isDollar)
// 海外添加当地币
Vue.filter('isLocal', isLocal)
Vue.filter('toThousandthAndKeepDecimal', toThousandthAndKeepDecimal)
Vue.prototype.stateFormat = stateFormat
//国际化
import i18n from '@/assets/i18n/index'
Vue.prototype.$echarts = echarts;
Vue.use(ElementUI)
Vue.config.productionTip = false;
router.afterEach((to, from, next) => {
  // window.scrollTo(0, 0);
})
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

