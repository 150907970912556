/*
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-01-10 09:59:30
 */
import router from './router'
import store from './store'
import { Message } from 'element-ui'
import { getToken } from '@/util/auth'

const whiteList = ['/login', '/bfPay', '/hfPay', '/freeLogin']
router.beforeEach((to, from, next) => {
  if (getToken()) {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      if (store.getters.userInfo.memberCode == undefined) {
        store.dispatch('GetInfo').then(() => {
          next()
        })
      } else {
        next()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
    }
  }
})
