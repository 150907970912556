/*
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-01-10 14:20:02
 */
const getters = {
  userInfo: state => state.user.userInfo,
  specialCarNum: state => state.user.specialCarNum,
  cont: state => state.user.cont,
  
}
export default getters