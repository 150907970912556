/*
 * @Descripttion:
 * @version:
 * @Author: kBank
 * @Date: 2023-01-30 09:32:38
 */
import request from '@/util/request'

// 获取会员等级
export function getMemLevel(data) {
  return request({
    url: '/sale/api/order/valid-mem-level',
    method: 'post',
    data
  })
}
// 获取地址列表
export function addressList(params) {
  return request({
    url: '/member/api/member-address/list',
    method: 'get',
    params
  })
}
// 获取省市区
export function allArea(params) {
  return request({
    url: '/system/api/area/tree',
    method: 'get',
    params
  })
}
// 保存地址
export function saveAddress(data) {
  return request({
    url: '/member/api/member-address/save',
    method: 'post',
    data
  })
}
// 保存礼品地址
export function saveGiftAddress(data) {
  return request({
    url: '/member/api/member-gift/update-gift',
    method: 'post',
    data
  })
}
// 获取会员等级
export function memLevel(data) {
  return request({
    url: '/sale/api/order/valid-mem-level',
    method: 'post',
    data
  })
}

// 获取国家
export function currencyList(params) {
  return request({
    url: '/system/api/country/list',
    method: 'get',
    params
  })
}
// 根据推荐人编号获取安置信息
export function validPcode(data) {
  return request({
    url: '/sale/api/order/valid-p-code',
    method: 'post',
    data
  })
}
// 一键注册
export function quickReg(data) {
  return request({
    url: '/sale/api/order/quick-reg',
    method: 'post',
    data
  })
}
// 注册订单验证安置人编号
export function validPPcode(data) {
  return request({
    url: '/sale/api/order/valid-p-p-code',
    method: 'post',
    data
  })
}

// 发货
export function deliveryList(params) {
  return request({
    url: '/system/pub/enums/delivery-list',
    method: 'get',
    params
  })
}
// 运输
export function transportList(params) {
  return request({
    url: '/system/pub/enums/transport-type',
    method: 'get',
    params
  })
}
// 银行列表
export function bankList(params) {
  return request({
    url: '/system/api/bank/list ',
    method: 'get',
    params
  })
}

// 注册订单验证手机号
export function validPhone(data) {
  return request({
    url: '/sale/api/order/valid-phone',
    method: 'post',
    data
  })
}
// 修改地址查询邮费
export function queryAdressPostage(data) {
  return request({
    url: '/sale/api/order/self-order-postage',
    method: 'post',
    data
  })
}
// 查询邮费
export function queryPostage(data) {
  return request({
    url: '/sale/api/order/query-order-postage',
    method: 'post',
    data
  })
}

// 查询邮费
export function queryPostage1(params) {
  return request({
    url: '/activity/api/pick/postage',
    method: 'get',
    params
  })
}
// 确认注册订单-生成待支付订单
export function confirmRegOrder(data) {
  return request({
    url: '/sale/api/order/confirm-reg-order',
    method: 'post',
    data
  })
}
// 幂等性
export function generate(params) {
  return request({
    url: '/system/api/idempotent/generate',
    method: 'get',
    params
  })
}
// 地址详情
export function addressDetail(data) {
  return request({
    url: '/member/api/member-address/detail/' + data,
    method: 'get',
  })
}
// 获取礼品地址详情
export function getGiftAddress(params) {
  return request({
    url: '/member/api/member-gift/detail',
    method: 'get',
    params
  })
}

// 删除地址
export function delAddress(data) {
  return request({
    url: '/member/api/member-address/' + data,
    method: 'delete',
  })
}
// 设为默认
export function addressDefault(data) {
  return request({
    url: '/member/api/member-address/default/' + data,
    method: 'put',
  })
}
// 升级获取name
export function upgRel(data) {
  return request({
    url: '/sale/api/order/validate-upg-rel',
    method: 'post',
    data
  })
}
// 升级复购单确认
export function confirmOthOrder(data) {
  return request({
    url: '/sale/api/order/confirm-oth-order',
    method: 'post',
    data
  })
}
// 自营合作支付校验
export function checkPay(data) {
  return request({
    url: '/sale/api/order/check-pay-type',
    method: 'post',
    data
  })
}
// 新人礼包确认
export function confirmGiftOrder(data) {
  return request({
    url: '/sale/api/order/confirm-gift-order',
    method: 'post',
    data
  })
}
// 提货下单
export function pickOrder(data) {
  return request({
    url: '/activity/api/pick/order',
    method: 'post',
    data
  })
}
// 提货卡详情
export function pickDetails(params) {
  return request({
    url: '/activity/api/pick/detail',
    method: 'get',
    params
  })
}
// 购物车商品详情
export function shoppingWaresDetail(data) {
  return request({
    url: '/sale/api/shopping/get-shopping-wares-detail',
    method: 'post',
    data
  })
}
// 嗨粉确认订单
export function confirmFunOrder(data) {
  return request({
    url: '/sale/api/order/confirm-fun-order',
    method: 'post',
    data
  })
}

// 注册页面显示权限
export function registerList(params) {
  return request({
    url: '/system/api/register-page/list',
    method: 'get',
    params
  })
}
// 上传图片
export function uploadImg(data) {
  return request({
    url: '/member/api/maker-space/update-file',
    method: 'post',
    data
  })
}
// 上传视频
export function uploadVideo(data) {
  return request({
    url: '/member/api/maker-space/update-video',
    method: 'post',
    data
  })
}
// 提交认证
export function markerSpaceSubmit(data) {
  return request({
    url: '/member/api/maker-space/submit',
    method: 'post',
    data
  })
}
// 查看提交状态
export function markerSpaceDetail(params) {
  return request({
    url: '/member/api/maker-space/detail',
    method: 'get',
    params
  })
}
// 删除图片
export function delImg(params) {
  return request({
    url: '/member/api/maker-space/delete-file',
    method: 'delete',
    params
  })
}
// 获取业绩
export function getPerFormance(params) {
  return request({
    url: '/sale/api/target-performance/target-performance-progress',
    method: 'get',
    params
  })
}
// 获取业绩
export function getPerFormanceAmount(params) {
  return request({
    url: '/sale/api/target-performance/target-performance-amount-progress',
    method: 'get',
    params
  })
}
// 协议
export function waresAgreement(data) {
  return request({
    url: '/sale/api/wares/get-wares-agreement ',
    method: 'post',
    data
  })
}

// 会员店铺
export function storeList(params) {
  return request({
    url: '/member/api/store/list',
    method: 'get',
    params
  })
}
// 仓库店铺
export function storehouseList(params) {
  return request({
    url: '/system/api/storehouse/list',
    method: 'get',
    params
  })
}
// 礼包领取
export function peoplePick(data) {
  return request({
    url: '/activity/api/people-service/peoplePick',
    method: 'post',
    data
  })
}
// 海粉订单详情
export function waresinfo(data) {
  return request({
    url: '/sale/api/wares/query-confirm-waresinfo',
    method: 'post',
    data
  })
}
// 海粉订单确认
export function confirmShareOrder(data) {
  return request({
    url: '/sale/api/order/confirm-share-order',
    method: 'post',
    data
  })
}
// 默认未默认
export function queryDefault(params) {
  return request({
    url: '/sale/api/order/query-default',
    method: 'get',
    params
  })
}
// 设为默认
export function updateDefault(data) {
  return request({
    url: '/sale/api/order/update-default',
    method: 'post',
    data
  })
}
// 获取商品下单详情
export function getWaresInfo(data) {
  return request({
    url: '/sale/api/wares/get-wares-info',
    method: 'post',
    data
  })
}
// 嗨粉分享
export function confirmFunShareOrder(data) {
  return request({
    url: '/sale/api/order/confirm-self-fun-order',
    method: 'post',
    data
  })
}
// 创客空间选择
export function list_maker(data) {
  return request({
    url: '/member/api/maker-space/list-maker?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize,
    method: 'post',
    data
  })
}
// 特殊会员完善信息
export function saveSpecial(data) {
  return request({
    url: '/member/api/member-special/save-special',
    method: 'post',
    data
  })
}
// 特殊信息列表
export function specialList(params) {
  return request({
    url: '/member/api/member-special/special-list',
    method: 'get',
    params
  })
}
// 实名认证详情
export function authenticationDetails(params) {
  return request({
    url: '/member/api/authentication/detail',
    method: 'get',
    params
  })
}
// 实名认证详情续约用
export function agreementName(params) {
  return request({
    url: '/member/api/authentication/agreement-real-name',
    method: 'get',
    params
  })
}
// 经销商认证
export function checkAttest(params) {
  return request({
    url: '/member/api/maker-space/check-attest',
    method: 'get',
    params
  })
}
// ocr
export function ocrIdCard(data) {
  return request({
    url: '/member/api/authentication/invoke-idCard',
    method: 'post',
    data
  })
}
// ocr北面
export function ocrIdCardBack(data) {
  return request({
    url: '/member/api/authentication/invoke-idCard-back',
    method: 'post',
    data
  })
}
// 创客空间接口
export function queryCard(data) {
  return request({
    url: '/sale/api/wares/query-cabin-card',
    method: 'post',
    data
  })
}
// 复购特殊商品列表
export function makerList(params) {
  return request({
    url: '/sale/api/order/list-maker-ware',
    method: 'get',
    params
  })
}

