/*
 * @Descripttion: 
 * @version: 
 * @Author: 王三华
 * @Date: 2023-09-09 11:07:24
 */
import Vue from 'vue'
import ElementUI from 'element-ui';

Vue.component('el-input', {
  extends: ElementUI.Input,
  created() {
    this.$on('change', (value) => {
      // this.$emit('input', value.replace(/\s+/g,""))
      this.$emit('input', value.replace(/(^\s*)|(\s*$)/g,""))
    })
  }
})
