/*
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2021-11-19 15:21:54
 */
import axios from 'axios'
import { Message, MessageBox,Loading } from 'element-ui'
import { getToken, getTokenMi } from '@/util/auth'
import store from '@/store'
import { tansParams, blobValidate } from "@/util/ruoyi"
import { saveAs } from 'file-saver'
import i18n from '@/assets/i18n/index'

export let isRelogin = { show: false };
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 100000 // request timeout
})
let downloadLoadingInstance

service.interceptors.request.use(
  config => {
    if (localStorage.getItem('lang')) {
      config.headers['Accept-Language'] = localStorage.getItem('lang');
    } else {
      config.headers['Accept-Language'] = 'zh-CN'
    }
    if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    if (getTokenMi()) {
      config.headers['token'] = getTokenMi()
    }
    config.headers['Source'] = '1'
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code == 401 || res.code == 402 || res.code == 403) {
      if (!isRelogin.show) {
        isRelogin.show = true;
        // i18n.t('ENU_P_TYPE0')
        MessageBox.confirm('登录状态已过期,请重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText:'取消', type: 'warning' }).then(() => {
          store.dispatch('LogOut').then(() => {
            location.href = '/';
          })
        }).catch(() => {
          isRelogin.show = false;
        });
      }
    } else if (res.code == 500) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
// 通用下载方法
export function download(url, params, filename, config) {
  downloadLoadingInstance = Loading.service({ text: '正在下载数据，请稍候', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' })
  return service.post(url, params, {
    transformRequest: [(params) => {
      return tansParams(params)
    }],
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    ...config
  }).then(async (data) => {
    const isLogin = await blobValidate(data)
    if (isLogin) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text()
      const rspObj = JSON.parse(resText)
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      Message.error(errMsg)
    }
    downloadLoadingInstance.close()
  }).catch((r) => {
    console.error(r)
    Message.error('下载文件出现错误，请联系管理员！')
    downloadLoadingInstance.close()
  })
}

export default service
