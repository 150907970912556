/*
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2022-09-28 15:22:34
 */
import Vue from "vue";
import Vuex from "vuex";
import user from './modules/user'
import getters from './getters'
Vue.use(Vuex);

export default new Vuex.Store({

  modules: {
    user
  },
  getters
});
