/*
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2022-07-05 17:57:49
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhCn from 'zhCN'
import enUS from 'enUS'
import zhTC from 'zhTC'
// import ruRU from 'ruRU'
// import frFR from 'frFR'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import twLocale from 'element-ui/lib/locale/lang/zh-TW'
import ruLocale from 'element-ui/lib/locale/lang/ru-RU'
import frLocale from 'element-ui/lib/locale/lang/fr'
const lang = localStorage.getItem('lang') || 'zh-CN'

Vue.use(VueI18n)


const i18n = new VueI18n({
  locale: lang,

  messages: {
    'zh-CN': { ...zhCn,...zhLocale },
    'en-US': { ...enUS,...enLocale },
    'zh-TC': { ...zhTC,...twLocale },
    // 'ru-RU': { ...ruRU,...ruLocale },
    // 'fr-FR': { ...frFR,...frLocale },
    // 'ru-RU': { ...ruRu },
    // 'kz-KZ': { ...kzKz },
  }

})

export default i18n


