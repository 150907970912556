/*
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-01-11 17:27:50
 */
import request from '@/util/request'

// 商品列表
export function getAllGoods(data) {
  return request({
    url: '/sale/api/wares/query-spe-wares',
    method: 'post',
    data
  })
}
// 专区列表
export function specialArea(params) {
  return request({
    url: '/system/pub/enums/special-area',
    method: 'get',
    params
  })
}
// 分类列表
export function classifyList(params) {
  return request({
    url: '/sale/api/area-classify/list',
    method: 'get',
    params
  })
}

// 商品详情
export function waresDetail(data) {
  return request({
    url: '/sale/api/wares/query-spe-wares-detail',
    method: 'post',
    data
  })
}
// 商品sku
export function waresDetailSku(data) {
  return request({
    url: '/sale/api/wares/query-wares-detail-sku',
    method: 'post',
    data
  })
}
// 添加购物车
export function addShopping(data) {
  return request({
    url: '/sale/api/shopping/addShopping',
    method: 'post',
    data
  })
}
// 多删购物车
export function batchDelShopping(data) {
  return request({
    url: '/sale/api/shopping/batchDelShopping',
    method: 'post',
    data
  })
}
// 加删购物车
export function plusReduceShopping(data) {
  return request({
    url: '/sale/api/shopping/plusReduceShopping',
    method: 'post',
    data
  })
}
// 获取购物车
export function getShopping(params) {
  return request({
    url: '/sale/api/shopping/getShopping',
    method: 'get',
    params
  })
}
// 获取购物车数量
export function getShoppingCount(params) {
  return request({
    url: '/sale/api/shopping/getShoppingCount',
    method: 'get',
    params
  }) 
}
// 获取购物车数小
export function getSmallCount(params) {
  return request({
    url: '/sale/api/shopping/getAreaShoppingCount',
    method: 'get',
    params
  }) 
}
// 切换语言
export function getLanguages(params) {
  return request({
    url: '/system/pub/enums/get-languages',
    method: 'get',
    params
  })
}
// 菜单权限
export function menuList(params) {
  return request({
    url: '/system/api/menu/list',
    method: 'get',
    params
  })
}
// 海粉商品
export function sharingWares(params) {
  return request({
    url: '/sale/api/wares/sharing-zone-wares',
    method: 'get',
    params
  })
}
// 海粉商品详情
export function sharingWaresDetail(params) {
  return request({
    url: '/sale/api/wares/query-spe-sharing-wares-detail',
    method: 'get',
    params
  })
}
// 海粉渠道
export function jxhhSource(params) {
  return request({
    url: 'sale/api/t-source/list/' + params,
    method: 'get',
  })
}
// 海粉分享
export function shareCode(params) {
  return request({
    url: '/member/api/share/share-code-h5',
    method: 'get',
    params
  })
}
//嗨粉分享
export function fansCode(params) {
  return request({
    url: '/member/api/share/fans-code-h5',
    method: 'get',
    params
  })
}
// 海粉分类
export function optionList(data) {
  return request({
    url: '/sale/api/twares-category-category/option-list',
    method: 'post',
    data
  })
}
// 精品推荐
export function recommend_wares(data) {
  return request({
    url: 'sale/api/wares/recommend_wares',
    method: 'post',
    data
  })
}
// 海粉精品推荐
export function other_recommend_wares(params) {
  return request({
    url: 'sale/api/wares/other_recommend_wares',
    method: 'get',
    params
  })
}
// 获取国家
export function currencyList(params) {
  return request({
    url: '/system/api/country/list',
    method: 'get',
    params
  })
}

//站内信未读数量
export function getIndexMailCount(params) {
  return request({
    url: '/system/api/notice/index-mail-count',
    method: 'get',
    params
  })
}
//获取钱符号
export function getCurrency(params) {
  return request({
    url: '/system/api/currency/getCurrency',
    method: 'get',
    params
  })
}
//树购买记录
export function treeList(params) {
  return request({
    url: '/activity/api/tree-rule-pick/member-info-list',
    method: 'get',
    params
  })
}

// 购物车注册下单校验
export function validShop(data) {
  return request({
    url: '/sale/api/order/valid-enough-shop',
    method: 'post',
    data
  })
}
// 购物车注册下单校验弹框
export function energyShop(data) {
  return request({
    url: '/sale/api/wares/query-energy-silo',
    method: 'post',
    data
  })
}
// 购物车注册下单校验弹框确认
export function energyShopSilo(data) {
  return request({
    url: '/sale/api/order/valid-silo',
    method: 'post',
    data
  })
}
