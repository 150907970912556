/*
 * @Descripttion:
 * @version:
 * @Author: kBank
 * @Date: 2022-09-28 15:22:34
 */
import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";

Vue.use(VueRouter);
//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)

}
const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("@/views/index.vue"),
    meta: {
      keepAlive: true//控制公共顶部菜单
    }
  },
  {
    path: "/specialMember",
    name: "SpecialMember",
    component: () => import("@/views/specialMember.vue"),
    meta: {
      keepAlive: false//控制公共顶部菜单
    }
  },
  // 免密登录
  {
    path: "/freeLogin",
    name: "FreeLogin",
    component: () => import("@/views/freeLogin.vue"),
    hidden: true
  },
  {
    path: "/shoppingMall",
    name: "ShoppingMall",
    component: () => import("@/views/shoppingMall/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 商品详情
  {
    path: "/goodsDetails",
    name: "GoodsDetails",
    component: () => import("@/views/goodsDetails/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 商品详情
  {
    path: "/shareDetails",
    name: "ShareDetails",
    component: () => import("@/views/shareDetails/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // // 抽奖详情
  // {
  //   path: "/giftDrwal",
  //   name: "GiftDrwal",
  //   component: () => import("@/views/giftDrwal/index.vue"),
  //   meta: {
  //     keepAlive: true
  //   }
  // },
  //业绩分布
  {
    path: "/marketDynamics",
    name: "MarketDynamics",
    component: () => import("@/views/marketDynamics/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  //活动专区
  {
    path: "/activeZone",
    name: "activeZone",
    component: () => import("@/views/marketDynamics/activeZone.vue"),
    meta: {
      keepAlive: true
    }
  },
  //活动详情
  {
    path: "/activeDetail",
    name: "activeDetail",
    component: () => import("@/views/marketDynamics/activeDetail.vue"),
    meta: {
      keepAlive: true
    }
  },
  //架构管理-安置架构
  {
    path: "/architecture",
    name: "architecture",
    component: () => import("@/views/frame/architecture.vue"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/azjg2",
    name: "azjg2",
    component: () => import("@/views/frame/azjg2.vue"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/azjg3",
    name: "azjg3",
    component: () => import("@/views/frame/azjg3.vue"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/azjg4",
    name: "azjg4",
    component: () => import("@/views/frame/azjg4.vue"),
    meta: {
      keepAlive: true
    }
  },
  //推荐架构
  {
    path: "/recommend",
    name: "recommend",
    component: () => import("@/views/frame/recommend.vue"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/tjjg2",
    name: "Tjjg2",
    component: () => import("@/views/frame/tjjg2.vue"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/tjjg3",
    name: "Tjjg3",
    component: () => import("@/views/frame/tjjg3.vue"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/tjjg4",
    name: "Tjjg4",
    component: () => import("@/views/frame/tjjg4.vue"),
    meta: {
      keepAlive: true
    }
  },
  //奖金明细
  {
    path: "/incomeDetail",
    name: "IncomeDetail",
    component: () => import("@/views/marketDynamics/incomeDetail.vue"),
    meta: {
      keepAlive: true
    }
  },
  //奖金来源
  {
    path: "/bonusSource",
    name: "BonusSource",
    component: () => import("@/views/marketDynamics/bonusSource.vue"),
    meta: {
      keepAlive: true
    }
  },
  //考核明细
  {
    path: "/appraisal",
    name: "Appraisal",
    component: () => import("@/views/marketDynamics/appraisal.vue"),
    meta: {
      keepAlive: true
    }
  },
  //考核明细-分红考核
  {
    path: "/dividend",
    name: "Dividend",
    component: () => import("@/views/marketDynamics/dividend.vue"),
    meta: {
      keepAlive: true
    }
  },
  //云代奖金
  {
    path: "/generation",
    name: "Generation",
    component: () => import("@/views/marketDynamics/generation.vue"),
    meta: {
      keepAlive: true
    }
  },

  //注册列表
  {
    path: "/registration",
    name: "Registration",
    component: () => import("@/views/marketDynamics/registration.vue"),
    meta: {
      keepAlive: true
    }
  },
  //招商列表
  {
    path: "/investment",
    name: "Investment",
    component: () => import("@/views/marketDynamics/investment.vue"),
    meta: {
      keepAlive: true
    }
  },
  //直推列表
  {
    path: "/pushList",
    name: "pushList",
    component: () => import("@/views/marketDynamics/pushList.vue"),
    meta: {
      keepAlive: true
    }
  },
  //海粉列表
  {
    path: "/fansList",
    name: "fansList",
    component: () => import("@/views/marketDynamics/fansList.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 商品详情
  {
    path: "/shoppingCart",
    name: "ShoppingCart",
    component: () => import("@/views/shoppingCart/index.vue"),
    meta: {
      keepAlive: true
    }
  },

  // 注册
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/register/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 升级
  {
    path: "/upgrade",
    name: "Upgrade",
    component: () => import("@/views/upgrade/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 复购
  {
    path: "/repurchase",
    name: "Repurchase",
    component: () => import("@/views/repurchase/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 福利
  {
    path: "/welfare",
    name: "Welfare",
    component: () => import("@/views/welfare/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 重消
  {
    path: "/rescission",
    name: "Rescission",
    component: () => import("@/views/rescission/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 分享
  {
    path: "/shareConfirm",
    name: "shareConfirm",
    component: () => import("@/views/shareConfirm/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 嗨粉分享
  {
    path: "/shareFansConfirm",
    name: "shareFansConfirm",
    component: () => import("@/views/shareFansConfirm/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 个人信息
  {
    path: "/personal",
    name: "Personal",
    component: () => import("@/views/personal/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/vipCenter",
    name: "VipCenter",
    component: () => import("@/views/vipCenter/index.vue"),
    meta: {
      keepAlive: true
    }
  },

  // 积分
  {
    path: "/integral",
    name: "Integral",
    component: () => import("@/views/integral/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/newGiftPay",
    name: "NewGiftPay",
    component: () => import("@/views/newGiftPay/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 直播会员
  {
    path: "/tvIng",
    name: "TvIng",
    component: () => import("@/views/tvIng/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  //站内信公告意见反馈
  {
    path: "/noticLists",
    name: "NoticLists",
    component: () => import("@/views/noticLists/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/noticeDetail",
    name: "NoticeDetail",
    component: () => import("@/views/noticLists/noticeDetail.vue"),
    meta: {
      keepAlive: true
    }
  },


  // 嗨粉
  {
    path: "/hiFans",
    name: "HiFans",
    component: () => import("@/views/hiFans/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // login
  {
    path: '/login',
    component: () => import('@/views/login.vue'),
  },

  //成长之路
  {
    path: "/roadtoGrowth",
    name: "RoadtoGrowth",
    component: () => import("@/views/roadtoGrowth/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  //乡村振兴礼包
  {
    path: "/spaceMallList",
    name: "SpaceMallList",
    component: () => import("@/views/spaceCreat/spaceMallList.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 乡村振兴礼包注册
  {
    path: "/spaceRegister",
    name: "SpaceRegister",
    component: () => import("@/views/spaceRegister/index.vue"),
    meta: {
      keepAlive: true
    }

  },
  //抗衰能量仓

  {
    path: "/energyWarehouse",
    name: "EnergyWarehouse",
    component: () => import("@/views/energyWarehouse/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 创客空间认证
  {
    path: "/markerSpace",
    name: "MarkerSpace",
    component: () => import("@/views/markerSpace/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 超级工作室认证
  {
    path: "/superStudio",
    name: "superStudio",
    component: () => import("@/views/superStudio/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 经销商认证
  {
    path: "/dealer",
    name: "dealer",
    component: () => import("@/views/dealer/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 专属礼包
  {
    path: "/giftBag",
    name: "giftBag",
    component: () => import("@/views/giftBag/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 注册业绩
  {
    path: "/performance",
    name: "performance",
    component: () => import("@/views/performance/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  //创客空间列表
  {
    path: "/makerspaceList",
    name: "makerspaceList",
    component: () => import("@/views/makerspaceList/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  //荣誉墙列表
  {
    path: "/honorList",
    name: "honorList",
    component: () => import("@/views/honorList/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  //创客空间详情
  {
    path: "/makerspaceDetail",
    name: "makerspaceDetail",
    component: () => import("@/views/makerspaceList/detail.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 提货支付
  {
    path: "/pickPay",
    name: "PickPay",
    component: () => import("@/views/pickPay/index.vue"),
    meta: {
      keepAlive: true
    }
  },
  // 宝付公众号支付
  {
    path: '/bfPay',
    component: () => import('@/views/bfPay.vue'),
  },
  // 汇付公众号支付
  {
    path: '/hfPay',
    component: () => import('@/views/hfPay.vue'),
  },

];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
