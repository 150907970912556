/*
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2021-11-19 15:21:54
 */
import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

const TokenMi = 'Mi-token'

export function getToken() {
  return Cookies.get(TokenKey)
}
export function setToken(token, expires) {
  return Cookies.set(TokenKey, token, { expires: expires })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function getTokenMi() {
  return Cookies.get(TokenMi)
}
export function setTokenMi(token) {
  return Cookies.set(TokenMi, token)
}
