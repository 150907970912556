/*
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-02-01 15:20:02
 */
import store from '@/store'
export function stateFormat(row, column, cellValue) {
  if (cellValue) {
    return Number(cellValue)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {

          return $1 + ',';
        })

        .replace(/\.$/, "");
  }
}
//element 转成千分位并保留小数
//decimal 默认保留2位小数
export function toThousandthAndKeepDecimal(element, decimal=2){
  if(!element || element === ''){//值不存在 或为空
    return ''
  }else if(typeof element == 'string'){
    return Number(element).toLocaleString(undefined, {minimumFractionDigits:decimal, maximumFractionDigits:decimal});
  }else if(typeof element == 'number'){
    return element.toLocaleString(undefined, {minimumFractionDigits:decimal, maximumFractionDigits:decimal});
  }else{
    return element;
  }

}
// 千分号
export function numberToCurrencyNo(value) {
  value = Number(value)
  if (!value) return 0
  if (value < 1) {
    return value
  }
  // 获取整数部分
  const intPart = Math.trunc(value)
  // 整数部分处理，增加,
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  // 预定义小数部分
  let floatPart = ''
  // 将数值截取为小数部分和整数部分
  const valueArray = value.toString().split('.')
  if (valueArray.length === 2) { // 有小数部分
    floatPart = valueArray[1].toString() // 取得小数部分
    return intPartFormat + '.' + floatPart
  } else {
    return intPartFormat + '.00'
  }
  // return intPartFormat + floatPart
}
// 海外添加$
export function isOther(value) {
  if (store.getters.userInfo.pkCountry != 1) {
    return '$'
  } else {
    return '￥'
  }
}
// 海外添加$
export function isDollar(value) {
  if (store.getters.userInfo.pkCountry != 1) {
    return '$' + value
  } else {
    return value
  }
}
// 海外添加当地币
export function isLocal(value) {
  if (store.getters.userInfo.pkCountry != 1) {
    return store.getters.userInfo.currencyIcon + value
  } else {
    return value
  }
}
// 当地币符号
export function isLocalSymbol(value) {
  return store.getters.userInfo.currencyIcon
}