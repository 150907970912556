/*
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-01-10 11:38:48
 */
import request from '@/util/request'
// 获取验证码
export function getCodeImg() {
  return request({
    url: '/code',
    method: 'get',
  })
}

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid
  }
  return request({
    url: '/member/auth/api/login',
    method: 'post',
    data: data
  })
}
// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/member/api/member/get-info',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/member/auth/api/logout',
    method: 'delete'
  })
}
// 忘记密码验证码
export function forgetSms(params) {
  return request({
    url: '/member/api/sms/forget-verification',
    method: 'get',
    params
  })
}
// 忘记密码更新
export function forgetPwd(data) {
  return request({
    url: '/member/api/member/forget-password',
    method: 'put',
    data
  })
}