<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2022-09-28 15:22:34
-->
<template>
  <div id="app">
    <div v-if="$route.meta.keepAlive">
      <HeaderTop></HeaderTop>
      <router-view></router-view>
    </div>
    <div v-if="!$route.meta.keepAlive">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import HeaderTop from "@/components/HeaderTop";
export default {
  components: {
    HeaderTop,
  },
};
</script>
<style lang="scss">
html {
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  width: 100%;
  height: 100%;
  background: #f9f9f9;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #d61820 !important;
}
.el-radio__input.is-checked .el-radio__inner {
  background: #d61820 !important;
  border-color: #d61820 !important;
}
// 定义滚动条高宽及背景高宽分别对应横竖滚动条的尺寸
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  // height: 250px;
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 5px;
  background: rgba(216, 216, 216, 1);
}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #c8161d;
}
</style>
