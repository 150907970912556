/*
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2022-12-14 18:04:53
 */
import { login, logout, getInfo } from '@/api/login'
import { getShoppingCount,getSmallCount } from '@/api/goods'
import { getToken, setToken, removeToken } from '@/util/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: [],
    userInfo: {},
    specialCarNum: 0,
    cont:0
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_specialCarNum: (state, specialCarNum) => {
      state.specialCarNum = specialCarNum
    },
    SET_count: (state, cont) => {
      state.cont = cont
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const code = userInfo.code
      const uuid = userInfo.uuid
      return new Promise((resolve, reject) => {
        login(username, password, code, uuid).then(res => {
          console.log('%c [ res ]-43', 'font-size:13px; background:#49028f; color:#8d46d3;', res)
          setToken(res.data.access_token, res.data.expires_in)
          commit('SET_TOKEN', res.data.access_token)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          commit('SET_USERINFO', res.data)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    SpecialQuantity({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        let obj
        if (typeof (data) == 'object') {
          obj = { specialArea: data.specialArea, pkCountry: data.pkCountry }
        } else {
          obj = { specialArea: data }
        }
        getShoppingCount(obj).then(res => {
          getSmallCount(obj).then(res1 => {
            commit('SET_specialCarNum', res1.data.smallCount)
            commit('SET_count', res.data.cont)
            resolve(res)
          })
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default user
