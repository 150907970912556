<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2022-09-29 09:05:39
-->
<template>
	<div>
		<div class="page">
			<div class="page_top">
				<div class="page_top_l"></div>
				<div class="page_top_r">
					<!-- <div @click="globalDivisions">{{ $t("w_0005") }}</div> -->
					<!-- <div @click="abount">{{ $t("w_0006") }}</div> -->
					<div>
						<i class="el-icon-phone-outline"></i>
						{{ $t("w_0021") }} 400-111-2818
					</div>
					<div @click="goMore(2)">
						<el-badge :value="wdNum" class="item">
							<i class="el-icon-message"></i>
							{{ $t("ENU_NOTICE_FUNCTION_TYPE_2") }}
						</el-badge>
					</div>
					<div @click="goMore(3)">
						<i class="el-icon-chat-dot-round"></i> {{ $t("S_L_15") }}
					</div>
					<!-- <div @click="openKf">
						<i class="el-icon-s-custom"></i> {{ $t("w_0023") }}
					</div> -->
				</div>
			</div>
			<div class="page_btm">
				<div class="page_btm_l">
					<span class="size-s">GLOBALIZATION</span>
					<span class="cheng">{{ $t("w_0024") }}</span>
				</div>
				<div class="page_btm_c">
					<div class="fHeigh" v-show="menu1" @click="goIndex">
						{{ $t("ENU_MENU_10") }}
					</div>
					<div class="fHeigh" v-show="menu2" @mouseover="changeActive(1)" @mouseleave="removeActive(1)">
						{{ $t("ENU_MENU_30") }}<i class="el-icon-arrow-down"></i>
						<div class="aBsolt" v-show="vipShow">
							<div v-for="item in zoneList" class="aBsolt_iu" :key="item.value" v-show="item.isShow"
								@mouseover="childrenActive(item.value)" @mouseleave="childrenRemActive(item.value)"
								@click="openZone(item)">
								<div class="aBsolt_ii">{{ item.label }}</div>
								<div class="aBsolt_c" v-if="childShow == item.value && item.children.length > 0">
									<div v-for="ctem in item.children" class="aBsolt_ic" :key="ctem.value"
										v-show="ctem.isShow" @click.stop="openZone(item, ctem)">
										{{ ctem.label }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="fHeigh" v-show="menu3" @mouseover="changeActive(2)" @mouseleave="removeActive(2)">
						{{ $t("ENU_MENU_50") }} <i class="el-icon-arrow-down"></i>
						<div class="aBsolt" v-show="marketShow">
							<div v-for="item in marketList" class="aBsolt_i" :key="item.id" v-show="item.isShow"
								@click="openPage1(item)">
								{{ item.text }}
							</div>
						</div>
					</div>
					<div class="fHeigh" v-show="menu4" @mouseover="changeActive(5)" @mouseleave="removeActive(5)">
						{{ $t("ENU_MENU_70") }} <i class="el-icon-arrow-down"></i>
						<div class="aBsolt" v-show="spaceShow">
							<div v-for="item in spaceList" class="aBsolt_i" :key="item.id" v-show="item.isShow"
								@click="openPage1(item)">
								{{ item.text }}
							</div>
						</div>
					</div>
					<div class="fHeigh" v-show="menu5" @mouseover="changeActive(3)" @mouseleave="removeActive(3)">
						{{ $t("ENU_MENU_90") }} <i class="el-icon-arrow-down"></i>
						<div class="aBsolt" v-show="vipcShow">
							<div v-for="item in vipList" class="aBsolt_i" :key="item.id" v-show="item.isShow"
								@click="openPage(item)">
								{{ item.text }}
							</div>
						</div>
					</div>
				</div>
				<div class="page_btm_r">
					<div class="page_btm_r1 fHeigh">
						<div class="disflex" @mouseover="changeActive(4)" @mouseleave="removeActive(4)">
							<img :src="userInfo.settleCountrySquareIcon" class="gq" alt="" />
							<div>
								{{ userInfo.memberCode }} <i class="el-icon-caret-bottom"></i>
							</div>
						</div>
						<!-- v-show="memberShow" -->
						<div class="aBsolts" v-show="memberShow" @mouseover="changeActive(4)"
							@mouseleave="removeActive(4)">
							<div class="aBsolt_i disflex cen" @click="goPersonInfo(1)">
								<img class="hImg" :src="
                    userInfo.headPath
                      ? userInfo.headPath
                      : userInfo.settleCountrySquareIcon
                  " alt="" />{{ userInfo.memberName }}
							</div>
							<div style="
                  width: 170px;
                  height: 1px;
                  background-color: #999;
                  margin: 10px auto;
                "></div>
							<div class="aBsolt_i" @click="goPersonInfo(0)">
								{{ $t("w_0025") }}
							</div>
							<div class="aBsolt_i" @click="goPersonInfo(2)">
								{{ $t("w_0026") }}
							</div>
							<div class="aBsolt_i" @click="goPersonInfo(4)">
								{{ $t("w_0027") }}
							</div>
							<!-- <div class="aBsolt_i"
                                       @click="goPersonInfo(3)">商城积分</div> -->
							<div style="
                  width: 170px;
                  height: 1px;
                  background-color: #999;
                  margin: 10px auto;
                "></div>
							<div class="aBsolt_i" @click="logout">{{ $t("w_0028") }}</div>
						</div>
					</div>
					<!-- <div class="page_btm_r2 fHeigh"
                                   @click="logout">退出</div> -->
					<!-- <div class="fHeigh">
                                简体中文 <i class="el-icon-caret-bottom"></i>
                              </div> -->
					<el-dropdown>
						<span class="el-dropdown-link">
							{{ selLabl }}<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown" class="user-dropdown">
							<el-dropdown-item v-for="(item, index) in languageList" :key="index">
								<div @click="setLanguage(item)">
									{{ item.label }}
								</div>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</div>
		<div class="zhan"></div>
	</div>
</template>

<script>
	import * as api from "@/api/goods.js";
	import * as ads from "@/api/register.js";

	import {
		mapGetters
	} from "vuex";
	export default {
		name: "Tabbar",
		data() {
			return {
				abounts: process.env.VUE_APP_BASE_API1 + "/#/aboutUs", // 关于我们
				globalDivision: process.env.VUE_APP_BASE_API1 + "/#/globalDivision", // 全球分布
				zoneList: [{
						label: this.$t("N_I_1"),
						value: 100,
						name: "share",
						children: [{
								label: this.$t("N_I_2"),
								value: 7,
								name: "hiFans",
							},
							{
								label: this.$t("N_I_3"),
								value: 21,
								name: "haiFans",
							},
						],
					},
					{
						label: this.$t("ENU_MENU_310"),
						value: 1,
						name: "regiest",
						children: [{
								label: this.$t("ENU_MENU_321"),
								value: 1,
								name: "regiestArea",
							},
							{
								label: this.$t("ENU_MENU_335"),
								value: 24,
								name: "ezLearnReg",
							},
						],
					},
					{
						label: this.$t("ENU_MENU_320"),
						value: 2,
						name: "upgrade",
						children: [{
								label: this.$t("ENU_MENU_321"),
								value: 2,
								name: "upgradeArea",
							},
							{
								label: this.$t("ENU_MENU_335"),
								value: 25,
								name: "ezLearnUp",
							},
							{
								label: this.$t("ENU_SPECIAL_A_7"),
								value: 27,
								name: "haiFunUpd",
							},
						],
					},

					{
						label: this.$t("ENU_MENU_330"),
						value: 3,
						name: "repurchase",
						children: [{
								label: this.$t("ENU_MENU_335"),
								value: 26,
								name: "ezLearnRep",
							},

							{
								label: this.$t("ENU_MENU_321"),
								value: 3,
								name: "self",
							},
							{
								label: this.$t("N_I_4"),
								value: 21,
								name: "haiFans",
							},
							{
								label: this.$t("ENU_MENU_332"),
								value: 22,
								name: "cooperation",
							},
							// {
							//   label: '直供专区',
							//   value: 21,
							//   name: 'direct',
							// },
							{
								label: this.$t("ENU_MENU_334"),
								value: 14,
								name: "live",
							},
							{
								label: this.$t("ENU_SHIPPING_C_3"),
								value: 12,
								name: "gongju",
							},
						],
					},
					// {
					//   label: this.$t("ENU_MENU_340"),
					//   value: 13,
					//   children: [],
					//   name: "welfare",
					// },
					// {
					//   label: this.$t('ENU_MENU_350'),
					//   value: 11,
					//   children: [],
					//   name: "integral",
					// },
					{
						label: this.$t("ENU_MENU_360"),
						value: 10,
						children: [],
						name: "rescission",
					},
					{
						label: "续约专区",
						value: 30,
						children: [],
						name: "renewalArea",
					},
					{
						label: "专供专区",
						value: 31,
						children: [],
						name: "wolesaleArea",
					},
					{
						label: this.$t("N_I_5"),
						value: 6,
						name: "frame",
						children: [{
								label: this.$t("w_0030"),
								value: 6,
								name: "architecture",
							},
							{
								label: this.$t("w_0031"),
								value: 7,
								name: "recommend",
							},
						],
					},
					{
						label: this.$t("w_1014"),
						value: 28,
						children: [],
						name: "makerArea",
					},
				],
				marketList: [{
						text: this.$t("MN_F_49"),
						id: 0,
						path: "/marketDynamics",
						name: "marketDynamics",
					},
					{
						text: this.$t("ENU_MENU_520"),
						id: 7,
						path: "/marketDynamics",
						name: "activeZone",
					},
					{
						text: this.$t("ENU_MENU_530"),
						id: 1,
						path: "/marketDynamics",
						name: "incomeDetail",
					},
					{
						text: this.$t("ENU_MENU_540"),
						id: 3,
						path: "/marketDynamics",
						name: "bonusSource",
					},
					{
						text: this.$t("ENU_MENU_560"),
						id: 4,
						path: "/marketDynamics",
						name: "appraisal",
					},
					{
						text: this.$t("ENU_MENU_570"),
						id: 5,
						path: "/marketDynamics",
						name: "registration",
					},
					{
						text: this.$t("ENU_MENU_580"),
						id: 6,
						path: "/marketDynamics",
						name: "investment",
					},
				],
				vipList: [{
						text: this.$t("ENU_MENU_901"),
						id: 0,
						path: "/vipCenter",
						name: "wallet",
					},
					{
						text: this.$t("ENU_MENU_902"),
						id: 1,
						path: "/vipCenter",
						name: "order",
					},
					{
						text: this.$t("ENU_MENU_903"),
						id: 2,
						path: "/vipCenter",
						name: "statistics",
					},
					// {
					//   text: this.$t('ENU_MENU_520'),
					//   id: 3,
					//   path: '/vipCenter',
					//   name: 'active',
					// },
					{
						text: this.$t("ENU_MENU_904"),
						id: 4,
						path: "/vipCenter",
						name: "pickGoods",
					},
					{
						text: this.$t("N_I_249"),
						id: 5,
						path: "/vipCenter",
						name: "selfHelp",
					},
				],
				spaceList: [{
						text: this.$t("N_I_6"),
						id: 0,
						path: "/energyWarehouse",
						name: "energyWarehouse",
					},
					{
						text: this.$t("w_1021"),
						id: 1,
						path: "/energyWarehouse",
						name: "markerSpace",
					},
					{
						text: this.$t("w_1022"),
						id: 2,
						path: "/energyWarehouse",
						name: "superStudio",
					},
					{
						text: this.$t("N_I_7"),
						id: 18,
						path: "/energyWarehouse",
						name: "spaceMallList",
					},
				],
				vipShow: false,
				marketShow: false,
				vipcShow: false,
				memberShow: false,
				spaceShow: false,
				selLabl: this.$t("w_0032"),
				languageList: [],
				childShow: 0,
				menu1: false, //首页
				menu2: false, //会员专区
				menu3: false, //市场动态
				menu4: false, //创客空间
				menu5: false, //会员中心
				wdNum: "", //站内信未读数量
			};
		},
		computed: {
			...mapGetters(["userInfo"]),
		},
		created() {
			this.getSpecialArea();
			this.getLanguages();
			this.getMenuList();
			this.getIndexMailCount();
		},
		methods: {
			//站内信未读数量
			getIndexMailCount() {
				api.getIndexMailCount().then((res) => {
					this.wdNum = res.data;
					if (this.wdNum == 0) {
						this.wdNum = "";
					}
				});
			},

			getMenuList() {
				// if (this.userInfo.registerShare == 0) {
				//   this.menu2 = true;
				//   this.zoneList = [
				//     {
				//       label: this.$t('N_I_1'),
				//       value: 100,
				//       name: "share",
				//       isShow: true,
				//       children: [
				//         {
				//           label: this.$t('N_I_3'),
				//           value: 21,
				//           name: "haiFans",
				//           isShow: true,
				//         },
				//       ],
				//     },
				//     {
				//     label:this.$t('ENU_MENU_320'),
				//     value: 2,
				//     name: "upgrade",
				//     isShow: true,
				//     children: [
				//       {
				//         label: this.$t('ENU_MENU_321'),
				//         value: 2,
				//         name: "upgradeArea",
				//         isShow: true,
				//       },
				//     ],
				//   },

				//   {
				//     label: this.$t('ENU_MENU_330'),
				//     value: 3,
				//     name: "repurchase",
				//     isShow: true,
				//     children: [
				//       {
				//         label: this.$t('ENU_MENU_321'),
				//         value: 3,
				//         name: "self",
				//         isShow: true,
				//       },
				//       {
				//         label: this.$t('N_I_4'),
				//         value: 21,
				//         name: 'haiFans',
				//         isShow: true,
				//       },
				//       {
				//         label: this.$t('ENU_MENU_332'),
				//         value: 22,
				//         name: "cooperation",
				//         isShow: true,
				//       },

				//     ],
				//   },
				//   ];

				// } else
				if (this.userInfo.registerFans == 0) {
					this.menu2 = true;
					this.zoneList = [{
						label: this.$t("N_I_1"),
						value: 100,
						name: "share",
						isShow: true,
						children: [{
							label: this.$t("N_I_2"),
							value: 7,
							name: "hiFans",
							isShow: true,
						}, ],
					}, ];
				} else {
					api.menuList().then((res) => {
						localStorage.setItem("menuList", JSON.stringify(res.data));
						res.data.forEach((item) => {
							if (item.menuKey == "index") {
								this.menu1 = true;
							}
							if (item.menuKey == "product") {
								this.menu2 = true;
							}
							if (item.menuKey == "market") {
								this.menu3 = true;
							}
							if (item.menuKey == "space") {
								this.menu4 = true;
							}
							if (item.menuKey == "center") {
								this.menu5 = true;
							}
							this.zoneList.forEach((ctem) => {
								
								if (ctem.name == item.menuKey) {
									ctem.isShow = true;
								}
								if (ctem.children.length > 0) {
									ctem.children.forEach((stem) => {
										if (stem.name == item.menuKey) {
											stem.isShow = true;
											ctem.isShow = true;
										}
									});
								}
								console.log(ctem.value == 13)
								if (ctem.value == 13) {
									ctem.isShow = false;
								}
							});
							console.log(this.zoneList)
							this.marketList.forEach((ctem) => {
								if (ctem.name == item.menuKey) {
									ctem.isShow = true;
								}
							});
							this.vipList.forEach((ctem) => {
								if (ctem.name == item.menuKey) {
									ctem.isShow = true;
								}
							});
							this.spaceList.forEach((ctem) => {
								if (ctem.name == item.menuKey) {
									if (item.menuKey == "markerSpace") {
										if (this.userInfo.makerSpaceShow == undefined) {} else {
											if (this.userInfo.makerSpaceShow == 1) {
												ctem.text = this.$t("w_1021");
												ctem.isShow = true;
											}
											if (this.userInfo.makerSpaceShow == 2) {
												ctem.text = this.$t("w_1033");
												ctem.isShow = true;
											}
										}
									} else {
										ctem.isShow = true;
									}
								}
							});
						});
					});
				}
			},
			globalDivisions() {
				window.open(this.globalDivision);
			},
			abount() {
				window.open(this.abounts);
			},
			goMore(index) {
				//1公告 2站内信 3意见反馈
				this.$router.push({
					path: "/noticLists",
					query: {
						index: index
					},
				});
			},
			openKf() {
				let src =
					"https://im1c5366d.7x24cc.com/phone_webChat.html?accountId=N000000033467&chatId=302384fb-eda1-436e-a5e9-c03a2dbd6e97&visitorId=" +
					this.userInfo.memberCode +
					"&nickName=" +
					this.userInfo.memberCode;
				window.open(src, "_blank");
			},
			getLanguages() {
				api.getLanguages().then((res) => {
					if (res.code == 200) {
						res.data.forEach((item) => {
							if (item.field == localStorage.getItem("lang")) {
								this.selLabl = item.label;
							}
						});
						if (
							localStorage.getItem("lang") == undefined ||
							localStorage.getItem("lang") == "undefined"
						) {
							localStorage.setItem("lang", "zh-CN");
						}
						this.languageList = res.data;
					}
				});
			},
			setLanguage(item) {
				localStorage.setItem("lang", item.field);
				this.selLabl = item.label;
				this.$i18n.locale = item.field;
				location.reload();
			},
			goPersonInfo(index) {
				this.$router.push({
					path: "/personal",
					query: {
						id: index,
					},
				});
			},
			getSpecialArea() {
				api.specialArea().then((res) => {
					res.data.forEach((item) => {
						item.path = "/shoppingMall";
					});
					// this.zoneList = res.data
				});
			},
			openPage(item) {
				this.$router.push({
					path: item.path,
					query: {
						id: item.value == 0 ? 0 : item.value || item.id
					},
				});
				this.vipShow = false;
				this.marketShow = false;
				this.vipcShow = false;
			},
			openZone(item, ctem) {
				if (item.value == 6) {
					if (ctem) {
						this.$router.push({
							name: ctem.name,
							query: {
								idTh: ctem.value
							},
						});
					} else {
						this.$router.push({
							name: "architecture",
							query: {
								idTh: item.value
							},
						});
					}
					return;
				}
				// console.log("🌈", item, ctem);
				if (ctem == undefined && item.children.length == 0) {
					if (item.value == 30) {
						this.getAuth();
					} else {
						this.$router.push({
							path: "/shoppingMall",
							query: {
								id: item.value
							},
						});
					}

					this.vipShow = false;
					this.marketShow = false;
					this.vipcShow = false;
				} else if (ctem != undefined && item.children.length > 0) {
					this.$router.push({
						path: "/shoppingMall",
						query: {
							id: item.value,
							idTh: ctem.value
						},
					});
					this.vipShow = false;
					this.marketShow = false;
					this.vipcShow = false;
				}
			},
			getAuth() {
				ads.agreementName().then((res) => {
					if (res.data == 0) {
						this.$router.push({
							path: "/shoppingMall",
							query: {
								id: 30
							},
						});
					} else {
						this.$confirm(`${this.$t("MY_WAL_50")}?`, this.$t("w_0034"), {
								confirmButtonText: this.$t("w_0035"),
								cancelButtonText: this.$t("ENU_P_TYPE0"),
								type: "warning",
							})
							.then(() => {
								this.$router.push({
									path: "/vipCenter",
									query: {
										id: 5,
										atabActiveId: 9
									},
								});
							})
							.catch(() => {});
					}
				});
			},
			openPage1(item) {
				this.$router.push({
					path: item.path,
					query: {
						id: item.id
					},
				});
				this.vipShow = false;
				this.marketShow = false;
				this.vipcShow = false;
			},
			goIndex() {
				this.$router.push({
					path: "/",
				});
			},
			childrenActive(id) {
				this.$set(this, "childShow", id);
				this.$forceUpdate();
			},
			childrenRemActive(id) {
				this.$set(this, "childShow", 0);
			},
			//鼠标移入
			changeActive(item) {
				if (item == 1) {
					this.vipShow = true;
				}
				if (item == 2) {
					this.marketShow = true;
				}
				if (item == 3) {
					this.vipcShow = true;
				}
				if (item == 4) {
					this.memberShow = true;
				}
				if (item == 5) {
					this.spaceShow = true;
				}
			},
			//鼠标移出
			removeActive(item) {
				if (item == 1) {
					this.vipShow = false;
				}
				if (item == 2) {
					this.marketShow = false;
				}
				if (item == 3) {
					this.vipcShow = false;
				}
				if (item == 4) {
					this.memberShow = false;
				}
				if (item == 5) {
					this.spaceShow = false;
				}
			},
			async logout() {
				this.$confirm(`${this.$t("w_0033")}?`, this.$t("w_0034"), {
						confirmButtonText: this.$t("w_0035"),
						cancelButtonText: this.$t("ENU_P_TYPE0"),
						type: "warning",
					})
					.then(() => {
						localStorage.setItem("pkCountry", "");
						localStorage.setItem("showInfo", 0);
						this.$store.dispatch("LogOut").then(() => {
							location.href = "/";
						});
					})
					.catch(() => {});
			},
		},
	};
</script>

<style lang="scss" scoped>
	::v-deep .el-badge__content.is-fixed {
		top: 15px;
		right: 0px;
	}

	.size-s {
		font-size: 24px;
		margin-left: 0px;
		padding-bottom: 3px;
	}

	.page {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1999;
	}

	.zhan {
		height: 102px;
	}

	.page_top {
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		line-height: 44px;
		padding: 5px 60px;
		background: #cccccc;
		color: #333333;
	}

	.page_top_l {
		flex: 1;
		font-size: 18px;
		color: #333;
	}

	.page_top_r {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 44px;

		div {
			cursor: pointer;
			color: #333;
			font-size: 18px;
		}
	}

	.page_btm {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 63px;
		font-size: 18px;
		padding: 0 30px;
		background: rgba(24, 24, 24, 0.84);
		color: #ffffff;

		.fHeigh {
			height: 63px;
			line-height: 63px;
			cursor: pointer;
			position: relative;
		}

		.page_btm_l {
			display: flex;
			flex-direction: column;
			justify-content: center;
			font-size: 18px;
			letter-spacing: 1px;
			//font-weight: 600;
			text-align: center;

			.cheng {
				font-size: 18px;
				letter-spacing: 5px;
				//padding-left: 30px;

				font-weight: inherit !important;
			}
		}

		.page_btm_r {
			display: flex;
			justify-content: center;

			.page_btm_r1 {
				margin-right: 20px;
			}

			.page_btm_r2 {
				margin-right: 40px;
			}
		}
	}

	.aBsolt {
		width: 237px;
		text-align: center;
		position: absolute;
		color: #333;
		line-height: 44px;
		background: #fff;
		box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
		border-radius: 8px 8px 8px 8px;
		padding: 10px 0; // padding: 10px 36px;
		transform: translate(-72px, 0);
		// bottom: 0;
	}

	.aBsolts {
		width: 237px;
		transform: translateX(0);
		text-align: center;
		position: absolute;
		color: #333;
		line-height: 44px;
		background: #fff;
		box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
		border-radius: 8px 8px 8px 8px;
		padding: 10px 0;
		margin-left: -30px; // padding: 10px 36px;
		// transform: translate(-72px, 0);
	}

	.aBsolt_i {
		// width: 165px;
		margin: 0 30px;
		position: relative;
	}

	.aBsolt_iu {
		// margin: 0 30px;
		position: relative;
	}

	.aBsolt_ii {
		margin: 0 30px;
	}

	.aBsolt_i:hover {
		color: #ffffff;
		background: #e80502;
		border-radius: 8px 8px 8px 8px;
	}

	.aBsolt_iu:hover .aBsolt_ii {
		color: #ffffff;
		background: #e80502;
		border-radius: 8px 8px 8px 8px;
	}

	.aBsolt_c {
		position: absolute;
		width: 237px;
		right: -237px;
		top: -10px;
		background: #fff;
		box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
		border-radius: 8px 8px 8px 8px;
		padding: 10px 0;
	}

	.aBsolt_ic {
		// width: 200px;
		color: #333;
	}

	.aBsolt_ic:hover {
		color: #ffffff;
		background: #e80502;
		margin: 0 30px;
		border-radius: 8px 8px 8px 8px;
	}

	.disflex {
		display: flex;
		align-items: center;
	}

	.cen {
		justify-content: center;
	}

	.gq {
		width: 39px;
		height: 26px;
		margin-right: 10px;
	}

	.hImg {
		width: 44px;
		height: 44px;
		margin-right: 10px;
	}

	.el-dropdown-link {
		height: 58px;
		line-height: 58px;
		font-size: 18px; // padding: 0 30px;
		color: #ffffff;
		font-weight: 400;
		cursor: pointer;
	}

	.page_btm_c {
		flex: 1;
		padding: 0 40px;
		display: flex;
		justify-content: space-between;
	}
</style>